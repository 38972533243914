import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { connect } from 'react-redux';
import ResizeObserver from 'resize-observer-polyfill';
import classnames from 'classnames';
import { isFunction } from '@common/methods/isFunction';
import { SideMenuContext } from '@modules/SideMenu/context/SideMenu.context';
import { withSideMenuContext } from '@modules/SideMenu/hoc/withSideMenuContext';
import { trans } from '../../modules/translation/translate';
import { enhanceComponent } from '../../wrappers/enhanceComponent';

Tippy.defaultProps = {
	...Tippy.defaultProps,
	arrow: true,
};

class ChildrenWithInstance extends React.PureComponent {
	static propTypes = {
		children: PropTypes.any,
		instance: PropTypes.any,
	};

	render() {
		return Children.map(this.props.children, (child) => {
			// console.log({child, instance: this.props.instance});
			return React.cloneElement(child, {
				tippyinstance: this.props.instance,
				...child.props,
			});
		});
	}
}

class Tooltip extends React.PureComponent {
	static sideMenuContext = SideMenuContext;
	static propTypes = {
		localTheme: PropTypes.any,
		content: PropTypes.any,
		isVisible: PropTypes.any,
		visible: PropTypes.any,
		enabled: PropTypes.bool,
		hideOnClick: PropTypes.any,
		children: PropTypes.any,
		passInstance: PropTypes.bool,
		handleComponentError: PropTypes.func,
		modal: PropTypes.any,
		onModal: PropTypes.bool,
		dispatch: PropTypes.func,
		mobile: PropTypes.bool,
		mobileEnable: PropTypes.bool,
		namespace: PropTypes.string,
		chatVisible: PropTypes.bool,
		sideMenuContext: PropTypes.any,
		zIndex: PropTypes.number,
		className: PropTypes.string,
		closeOnScrollElemClass: PropTypes.string,
	};

	constructor(props) {
		super(props);
		this.state = {
			instance: '',
			forceHide: false,
		};
		this.modalResizeObserver =
			process.browser && ResizeObserver
				? new ResizeObserver(() => {
						this.popperForceUpdate();
				  })
				: null;
		this.strategy = React.createRef();
		this.element = React.createRef();
	}

	componentDidMount() {
		this.setForceHide();
		this.setOnModalResize();
		this.closeOnScrollEventAdd();
	}

	componentWillUnmount() {
		const modalElement = this.getModalElement();
		if (modalElement && isFunction(this.modalResizeObserver?.unobserve)) {
			this.modalResizeObserver.unobserve(modalElement);
		}

		this.closeOnScrollEventRemove();
	}

	setInstance = (instance) => {
		this.setState({
			instance,
		});
	};

	componentDidUpdate(prevProps) {
		if (
			prevProps.modal !== this.props.modal ||
			prevProps.mobile !== this.props.mobile ||
			prevProps.chatVisible !== this.props.chatVisible ||
			prevProps.sideMenuContext?.isOpen !== this.props.sideMenuContext?.isOpen
		) {
			this.popperForceUpdate();
			this.setForceHide();
		}

		if (
			prevProps.content !== this.props.content ||
			!!prevProps.visible !== !!this.props.visible
		) {
			this.setOnModalResize();
		}
	}

	isOnModal = () => {
		return typeof this.props.onModal === 'boolean'
			? this.props.onModal
			: this.getModalElement();
	};

	getModalElement = () => {
		return this.element.current ? this.element.current.closest('.modal') : null;
	};

	popperForceUpdate = () => {
		setTimeout(() => {
			if (isFunction(this.state.instance?.popperInstance?.forceUpdate)) {
				this.state.instance.popperInstance.forceUpdate();
			}
		}, 300);
	};

	setOnModalResize = () => {
		const modalElement = this.getModalElement();
		if (modalElement && isFunction(this.modalResizeObserver?.observe)) {
			this.modalResizeObserver.observe(modalElement);
		}
	};

	setForceHide = () => {
		const onModal = this.isOnModal();
		this.setState({
			forceHide:
				(this.props.modal && !onModal) ||
				(!onModal &&
					this.props.mobile &&
					(this.props.chatVisible || this.props.sideMenuContext?.isOpen)),
		});
	};

	onShown = () => {
		const input = this.element?.current?.querySelector?.('input');
		if (!input) {
			return;
		}
		const isInputFocus = document.activeElement === input;
		if (!isInputFocus) {
			input.focus();
		}
	};

	closeOnScroll = () => {
		if (this.state.instance.state.isVisible) {
			this.state.instance.hide();
		}
	};

	closeOnScrollEventAdd = () => {
		if (!this.props.closeOnScrollElemClass) {
			return;
		}

		const elem = document.querySelector(
			`.${this.props.closeOnScrollElemClass}`,
		);

		elem?.addEventListener('scroll', this.closeOnScroll, false);
	};

	closeOnScrollEventRemove = () => {
		if (!this.props.closeOnScrollElemClass) {
			return;
		}

		const elem = document.querySelector(
			`.${this.props.closeOnScrollElemClass}`,
		);

		elem?.removeEventListener('scroll', this.closeOnScroll, false);
	};

	render() {
		const {
			children,
			passInstance,
			localTheme,
			namespace,
			className,
			...props
		} = this.props;

		delete props.handleComponentError;
		delete props.modal;
		delete props.className;
		delete props.dispatch;
		delete props.onModal;
		delete props.mobile;
		delete props.mobileEnable;
		delete props.chatVisible;
		delete props.sideMenuContext;
		delete props.closeOnScrollElemClass;

		if (!children) {
			return false;
		}

		if (!props.content || typeof props.content === 'boolean') {
			return children;
		}

		if (
			(props.content &&
				typeof props.content === 'object' &&
				('message' in props.content || 'values' in props.content)) ||
			namespace
		) {
			props.content = trans({ label: props.content, namespace });
		}

		const content = (
			<div onMouseEnter={this.setForceHide} ref={this.element}>
				{passInstance && (
					<ChildrenWithInstance instance={this.state.instance}>
						{children}
					</ChildrenWithInstance>
				)}
				{!passInstance && children}
			</div>
		);

		if (
			this.state.forceHide ||
			(this.props.mobile &&
				this.props.localTheme !== 'error' &&
				!this.props.mobileEnable &&
				!passInstance)
		) {
			return content;
		}

		return (
			<Tippy
				interactive={false}
				zIndex={this.props.onModal ? 99998 : 9997}
				className={classnames(
					{ onModal: this.props.onModal },
					className,
					'text-style-sm-medium',
				)}
				onShown={this.onShown}
				onCreate={this.setInstance}
				theme={localTheme || ''}
				visible={props.isVisible}
				{...props}
			>
				{content}
			</Tippy>
		);
	}
}

Tooltip = enhanceComponent({
	Tooltip,
});

const mapStateToProps = (state) => {
	return {
		modal: !!state.modal?.id,
		mobile: state.app.mobile,
		// TODO: chat store usage
		chatVisible: state?.chat?.visible,
	};
};
//
// const mapDispatchToProps = (dispatch) => {
//   return {
//   };
// };

Tooltip = connect(mapStateToProps, null)(Tooltip);

export default withSideMenuContext(Tooltip);
